<template>
  <div class="reader-suggestions-slimform">
    <div class="reader-suggestions-slimform__body">
      <button
        v-if="isCollapsed"
        class="collapsed-button-wrapper"
        @click="isCollapsed = !isCollapsed"
      >
        <slot name="collapsed-button-content">
          <div class="reusable-reader-suggestions">
            <div>
              Fragen, Tipps oder Fotos?<br />
              Hier kannst du sie einreichen!
            </div>
            <div>
              <button>Einreichen</button>
            </div>
          </div>
        </slot>
      </button>
      <div v-else-if="uploadDone" class="reader-suggestions-slimform__content">
        <h3>
          <slot name="title">Tipps, Fragen oder Fotos?</slot>
        </h3>
        <div class="intro-text">
          <slot name="success-message">
            <p>Herzlichen Dank für Ihre Zusendung.</p>
          </slot>
        </div>
      </div>

      <div v-else class="reader-suggestions-slimform__content">
        <div v-show="showUploadGallery">
          <UploadGallerySlim
            :uploaded-files="uploadedFiles"
            :allowed-mime-types="allowedMimeTypes"
            :random-id="randomId"
            @toggle-gallery="toggleGallery"
            @upload-file="uploadFile"
            @remove-file="removeFile"
          />
        </div>
        <div v-show="!showUploadGallery">
          <h3>
            <slot name="title">Tipps, Fragen oder Fotos?</slot>
          </h3>
          <div class="intro-text">
            <slot name="intro-message" />

            <div v-if="hasError" class="error-text">
              <slot name="error-message">
                Es hat einen Fehler gegeben! Bitte versuchen Sie es noch einmal.
              </slot>
            </div>
            <FormKit
              v-slot="{ state: { valid } }"
              v-model="readerSuggestion"
              type="form"
              :actions="false"
              @submit="submitData"
            >
              <div class="firstrow">
                <FormKit
                  type="textarea"
                  name="sugg_story"
                  placeholder="Teile deine Tipps!"
                  :label="
                    labels.label_sugg_story
                      ? `${labels.label_sugg_story} *`
                      : 'Teile deine Tipps! *'
                  "
                  :sections-schema="{
                    label: null,
                  }"
                  validation="required"
                />

                <div class="formkit-input-upload-area">
                  <FormKit
                    :id="`sugg_files_${randomId}`"
                    type="file"
                    name="sugg_files"
                    :accept="allowedMimeTypes.toString()"
                    multiple="true"
                    :sections-schema="{
                      inner: {
                        $el: 'div',
                        children: [
                          {
                            if: '$slots.fileList',
                            then: '$slots.fileList',
                          },
                          {
                            $el: 'div',
                            attrs: {
                              class: {
                                if: '$slots.fileList',
                                then: 'formkit-input-upload-area-inner--hidden',
                                else: 'formkit-input-upload-area-inner',
                              },
                            },
                            children: [
                              {
                                $el: 'input',
                                bind: '$attrs',
                                attrs: {
                                  'type': 'file',
                                  'disabled': '$disabled',
                                  'name': '$node.name',
                                  'onChange': '$handlers.files',
                                  'onBlur': '$handlers.blur',
                                  'id': '$id',
                                  'aria-describedby': '$describedBy',
                                },
                              },
                              {
                                $el: 'div',
                                attrs: {
                                  class: {
                                    if: '$slots.fileList',
                                    then: 'formkit-input-upload-mask--hidden',
                                    else: 'formkit-input-upload-mask',
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    }"
                    @change="uploadFile"
                  >
                    <template v-if="uploadedFiles.length > 0" #fileList>
                      <UploadPreviewSlim :uploaded-files="uploadedFiles" @toggle-gallery="toggleGallery" />
                    </template>
                    <template #noFiles />
                  </FormKit>
                </div>
              </div>

              <FormKit
                name="sugg_name"
                label="Vor- und Nachname *"
                validation="required|alpha_spaces:default"
                :placeholder="displayPlaceholders ? 'Max Mustermann' : ''"
              />

              <FormKit
                type="email"
                name="sugg_email"
                label="E-Mail-Adresse *"
                validation="required|email"
                :placeholder="
                  displayPlaceholders ? 'max.mustermann@example.com' : ''
                "
              />

              <FormKit
                v-if="displayPhoneField"
                name="sugg_phone"
                label="Telefonnummer"
                :placeholder="displayPlaceholders ? '+43 (0) 664 8589828' : ''"
              />

              <FormKit
                :id="`sugg_accept_terms_${randomId}`"
                type="checkbox"
                name="sugg_accept_terms"
                validation="accepted"
              >
                <template #label="{ id }">
                  <label
                    v-if="labels.label_sugg_accept_terms"
                    :for="id"
                    class="label"
                    v-html="labels.label_sugg_accept_terms"
                  />
                  <label
                    v-else
                    :for="id"
                    class="label"
                  >
                    Ich erkläre, dass dich der Urheber der eingesandten Bilder bin und die nötigen Rechte daran besitze. Mit dem Absenden dieses Formulars erkläre ich mich mit der
                    <a
                      href="https://www.vol.at/agb"
                      target="_blank"
                    >
                      zweckgebundenen Speicherung der angegebenen Daten
                    </a>
                    einverstanden.
                  </label>
                </template>
              </FormKit>

              <div class="ml-auto">
                <FormKit type="submit" :disabled="!valid || isSubmitting">
                  Abschicken
                </FormKit>
              </div>
            </FormKit>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  FileUploadResult,
  ReaderSuggestionsFormLabels,
  ReaderSuggestionsFormValues,
} from '../types'
import { clearErrors, defaultConfig, plugin, setErrors } from '@formkit/vue'
import { getCurrentInstance, ref, watch } from 'vue'
import {
  createReaderSuggestionApi,
  deleteFileApi,
  storeReaderSuggestionApi,
  uploadFileApi,
} from './api'
import formkitConfig from './formkit.config'
import UploadGallerySlim from './UploadGallerySlim.vue'
import UploadPreviewSlim from './UploadPreviewSlim.vue'
import '../style.css'

const props = withDefaults(
  defineProps<{
    apiKey: string
    apiUrl: string
    collapsed?: boolean
    displayPhoneField?: boolean
    displayPlaceholders?: boolean
    prefillEmail?: string
    prefillName?: string
    prefillPhone?: string
    labels?: ReaderSuggestionsFormLabels
  }>(),
  {
    collapsed: true,
    displayPhoneField: false,
    displayPlaceholders: false,
    prefillEmail: '',
    prefillName: '',
    prefillPhone: '',
    labels: () => ({}),
  },
)

function isFormkitPluginRegistered() {
  const appInstance = getCurrentInstance()?.appContext.app
  return appInstance?.config?.globalProperties?.$formkit ?? false
}

if (!isFormkitPluginRegistered()) {
  getCurrentInstance()?.appContext.app.use(plugin, defaultConfig(formkitConfig))
}
else {
  console.warn('Formkit already registered')
}

const randomId = ref(Math.random().toString(36).slice(2, 11))

const isCollapsed = ref(props.collapsed)
const readerSuggestion = ref<ReaderSuggestionsFormValues>({
  id: undefined,
  api_token: props.apiKey,
  sugg_email: props.prefillEmail,
  sugg_name: props.prefillName,
  sugg_phone: props.prefillPhone,
  sugg_accept_terms: false,
  sugg_allow_promotions: false,
  sugg_story: undefined,
  sugg_files: undefined,
  sugg_form_type: 'slim',
})
const showUploadGallery = ref(false)
const isSubmitting = ref(false)
const uploadDone = ref(false)
const hasError = ref(false)
const lastError = ref(null as null | Error)
const allowedMimeTypes = ref([
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/webp',
  'image/gif',
  'audio/wave',
  'audio/wav',
  'audio/x-wav',
  'audio/x-pn-wav',
  'video/mp4',
  'application/x-mpegURL',
  'video/MP2T',
  'video/3gpp',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
])

const uploadedFiles = ref([] as FileUploadResult[])

watch(() => [props.prefillEmail, props.prefillName, props.prefillPhone], () => {
  readerSuggestion.value.sugg_email = props.prefillEmail
  readerSuggestion.value.sugg_name = props.prefillName
  readerSuggestion.value.sugg_phone = props.prefillPhone
})

async function maybeCreateReaderSuggestionId(): Promise<void> {
  const id = readerSuggestion.value.id
  if (id === undefined) {
    try {
      const id = await createReaderSuggestionApi(props.apiUrl, props.apiKey)
      readerSuggestion.value.id = id
    }
    catch (ex) {
      console.error(ex)
      lastError.value = ex as Error
    }
  }
}

async function submitData(): Promise<void> {
  isSubmitting.value = true
  try {
    await maybeCreateReaderSuggestionId()
    const apiResponse = await storeReaderSuggestionApi(
      props.apiUrl,
      props.apiKey,
      { ...readerSuggestion.value, sugg_files: uploadedFiles.value },
    )
    if (apiResponse.status === true) {
      uploadDone.value = true
    }
  }
  catch (ex) {
    console.error(ex)
    lastError.value = ex as Error
  }
  isSubmitting.value = false
}

async function uploadFile(e: any): Promise<any> {
  if (e.target.files.length === 0) {
    return
  }

  let showErrorMessage = false;

  [...e.target.files].forEach(async (file: File) => {
    if (hasFileAlready(file)) {
      return
    }

    if (!allowedMimeTypes.value.includes(file.type)) {
      removeFileFromInput(file)
      showErrorMessage = true
      return
    }

    await maybeCreateReaderSuggestionId()
    const apiResponse: any = await uploadFileApi(
      props.apiUrl,
      props.apiKey,
      file,
      {
        id: `${readerSuggestion.value.id}`,
      },
    )

    if (!apiResponse.name) {
      return
    }

    uploadedFiles.value.push(apiResponse)
  })

  if (showErrorMessage) {
    setTimeout(() => {
      const msg = 'Dieses Dokument konnte nicht hochgeladen werden. Es werden nur ausgewählte Video-, Bild- und Textdateien akzeptiert.'
      const ids = [`sugg_files_${randomId.value}`, `sugg_files1_${randomId.value}`]
      ids.forEach(id => setErrors(id, msg))
    }, 200)
  }
}

function toggleGallery() {
  const ids = [`sugg_files_${randomId.value}`, `sugg_files1_${randomId.value}`]
  ids.forEach(id => clearErrors(id))
  showUploadGallery.value = !showUploadGallery.value
}

function hasFileAlready(file: any): any {
  return uploadedFiles.value.find(f => f.name === file.name)
}

function removeFileFromInput(file: any): void {
  const fileInputs = document.querySelectorAll(
    `input[name="sugg_files"]`,
  ) as NodeListOf<HTMLInputElement>

  fileInputs.forEach((fileInput) => {
    if (fileInput.files && fileInput.files.length > 0) {
      const files = Array.from(fileInput.files)
      const index = files.findIndex(f => f.name === file.name)
      if (index > -1) {
        files.splice(index, 1)
        const newFileList = new DataTransfer()
        files.forEach(file => newFileList.items.add(file))
        fileInput.files = newFileList.files
      }
    }
  })
}

async function removeFile(file: any): Promise<void> {
  await deleteFileApi(props.apiUrl, props.apiKey, file)

  removeFileFromInput(file)

  uploadedFiles.value = uploadedFiles.value.filter((f: any) => {
    return f.name !== file.name
  })
}
</script>

<style>
.reader-suggestions-slimform {
  @apply bg-gray-100 rounded-lg px-4 py-3 font-greta;
  &__body {
    .collapsed-button-wrapper {
      @apply w-full;

      .reusable-reader-suggestions {
        @apply flex items-start text-left bg-white p-2 text-grau-600 justify-between;

        button {
          @apply py-2 px-4 border border-transparent rounded-md text-text bg-main-600 gap-x-1 uppercase font-extrabold font-gretaCondensed text-xl ml-auto;

          &:focus,
          &:hover {
            @apply bg-black text-main-600 outline-none;
          }
        }
      }
    }
  }

  &__content {
    @apply min-h-96;

    h3 {
      @apply font-greta text-xl font-bold mb-4;
    }

    .intro-text {
      @apply text-base sm:text-lg;

      ul {
        @apply list-disc px-6 py-2;
      }

      a {
        @apply underline hover:no-underline text-text;
      }
    }
  }

  .formkit-form {
    .firstrow {
      @apply flex gap-4;
      > :first-child {
        @apply flex-grow;
      }
    }
    .formkit-input-upload-area {
      @apply w-full max-w-[200px];
    }

    .form-element[role='group'] {
      @apply md:col-span-2 bg-white border border-gray-100 shadow-sm rounded-md p-4;
    }

    .formkit-label {
      @apply block text-base sm:text-lg text-text;
    }

    .formkit-messages {
      @apply px-0 pt-0 -mt-2;

      .formkit-message {
        @apply text-red-500 text-base hidden;

        &:first-of-type {
          @apply block;
        }
      }
    }

    .formkit-input {
      @apply text-base border-solid border border-transparent block w-full rounded-md shadow-sm p-2 mb-4 sm:text-lg;
    }

    .formkit-input:disabled {
      @apply bg-gray-200;
    }

    .formkit-input[type='checkbox'] {
      @apply inline-block focus:ring-blue-500 h-4 w-4 text-blue-600 border-grau-600 rounded-md p-0 mt-1;
    }

    button.formkit-input {
      @apply py-2 px-4 border border-transparent rounded-md text-text bg-main-600 gap-x-1 uppercase font-extrabold font-gretaCondensed text-xl w-auto ml-auto;

      &:focus,
      &:hover {
        @apply bg-black text-main-600 outline-none;
      }

      &:disabled {
        @apply bg-grau-500 text-text opacity-75;
      }

      &.form-field-check {
        @apply bg-green-600;

        &:focus {
          @apply bg-green-400;
        }

        &:disabled {
          @apply bg-green-200;
        }
      }

      &.form-field-delete {
        @apply bg-red-600;

        &:focus {
          @apply bg-red-400;
        }

        &:disabled {
          @apply bg-red-200;
        }
      }
    }

    button.form-field:not(:disabled) {
      @apply hover:bg-black hover:text-main-600 focus:outline-none;

      &.form-field-check {
        @apply hover:bg-green-700 focus:ring-green-500 focus:ring-2 focus:ring-offset-2;
      }
      &.form-field-delete {
        @apply hover:bg-red-700 focus:ring-red-500 focus:ring-2 focus:ring-offset-2;
      }
    }

    *[data-is-showing-errors] .form-field {
      @apply border-red-500;
    }

    .formkit-input-upload-area input {
      @apply appearance-none absolute inset-0 opacity-0 z-10 h-full w-full;
    }

    .formkit-input-upload-area .formkit-messages {
      @apply pt-4;
    }

    .formkit-input-upload-area .formkit-input-upload-area-inner {
      @apply relative w-full aspect-square;
    }

    .formkit-input-upload-area .formkit-input-upload-area-inner--hidden {
      @apply hidden;
    }

    .formkit-input-upload-mask {
      @apply rounded-md absolute pointer-events-none flex items-center justify-center inset-0 border border-dashed border-grau-600 bg-grau-500;
    }

    .formkit-input-upload-area .formkit-input-upload-mask--hidden {
      @apply hidden;
    }

    .formkit-input-upload-mask:before {
      @apply bg-grau-800 h-8 w-8 absolute pointer-events-none;
      content: '';
      -webkit-mask-image: url('data:image/svg+xml,%3Csvg width="32" height="31" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="15.6" cy="15.5" r="14.9" stroke="%23979797"/%3E%3Cpath d="M6.1 15.1h18.3M15.6 7.2v16.6" stroke="%23979797" stroke-width="2" stroke-linecap="round"/%3E%3C/svg%3E');
      mask-image: url('data:image/svg+xml,%3Csvg width="32" height="31" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="15.6" cy="15.5" r="14.9" stroke="%23979797"/%3E%3Cpath d="M6.1 15.1h18.3M15.6 7.2v16.6" stroke="%23979797" stroke-width="2" stroke-linecap="round"/%3E%3C/svg%3E');
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-position: center;
      mask-position: center;
    }

    .formkit-input-upload-area
      input:not(:disabled):focus
      ~ .formkit-input-upload-mask,
    .formkit-input-upload-area
      input:not(:disabled):hover
      ~ .formkit-input-upload-mask,
    .formkit-input-upload-area
      input[data-is-drag-hover]:not(:disabled)
      ~ .formkit-input-upload-mask {
      @apply border-text;
    }

    .formkit-input-upload-area
      input:not(:disabled):focus
      ~ .formkit-input-upload-mask:before,
    .formkit-input-upload-area
      input:not(:disabled):hover
      ~ .formkit-input-upload-mask:before,
    .formkit-input-upload-area
      input[data-is-drag-hover]:not(:disabled)
      ~ .formkit-input-upload-mask:before {
      @apply bg-text;
    }

    .formkit-input-upload-area
      input:is(:disabled)
      + div.formkit-input-upload-mask {
      @apply bg-gray-200;
    }

    select.form-field {
      @apply py-2;
    }

    textarea.formkit-input {
      @apply min-h-[12rem];
    }

    .formkit-help {
      @apply text-xs text-text;
    }

    div[data-type='checkbox'] {
      & > .formkit-wrapper {
        @apply flex items-start space-x-4 mb-4;

        & label,
        a {
          @apply text-grau-600;
        }
      }
    }
    .label {
      @apply block select-none text-base text-text;
    }
  }
}
</style>
