<template>
  <div class="upload-preview-container-slimform" @click.prevent="emit('toggleGallery')">
    <button class="media-badge">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 927.9 772.2"
      >
        <path d="M864 0H64C29 0 0 29 0 64v644c0 36 29 64 64 64h800c35 0 64-28 64-64V64c0-35-29-64-64-64Zm15 684L713 451c-4-6-11-10-19-10s-15 3-20 9l-93 115-196-223a24 24 0 0 0-36 0L49 666V64c0-8 6-15 15-15h800c8 0 15 6 15 15v620Z" /><path d="M689 195a94 94 0 1 0 0 188 94 94 0 0 0 0-188Z" /></svg>
      <div>
        {{ uploadedFiles.length }} Fotos
      </div>
    </button>

    <img
      v-if="uploadedFiles[0].type.startsWith('image/')"
      :src="uploadedFiles[0].url"
      class="object-cover w-full h-full"
    />

    <template v-else-if="uploadedFiles[0].type.startsWith('video/')">
      <video controls="true">
        <source :src="uploadedFiles[0].url" :type="uploadedFiles[0].type" />
      </video>
    </template>

    <template v-else>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-3/5 h-3/5"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
        />
      </svg>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { FileUploadResult } from '../types'

defineProps<{
  uploadedFiles: FileUploadResult[]
}>()

const emit = defineEmits<{
  (event: 'toggleGallery'): void
}>()
</script>

<style>
    .upload-preview-container-slimform {
      @apply relative flex items-center justify-center overflow-hidden bg-grau-500 border border-grau-800 border-dashed rounded cursor-pointer min-h-6 aspect-square;

      .media-badge {
        @apply  absolute flex items-center font-bold gap-1 z-20 text-xs p-1 text-white bg-grau-800 hover:bg-text rounded right-1 top-1 sm:right-2 sm:top-2 uppercase;

        svg {
          @apply h-3 fill-white;
        }
      }
    }
</style>
