<template>
  <div class="upload-gallery-header">
    <div class="upload-gallery-header__breadcrumb" @click.prevent="emit('toggleGallery')">
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 18"
      ><path
        d="M10 1 5.3 8.5a1 1 0 0 0 0 1L10 17"
        stroke="#1A1603"
        stroke-width="2"
        stroke-linecap="round"
      /></svg>
      <span>Alle Fotos</span>
    </div>

    <svg
      class="upload-gallery-header__close"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 13"
      @click.prevent="emit('toggleGallery')"
    >
      <path
        d="m2 2 10 10M2 12 12 2"
        stroke="#1A1603"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>
  <div class="upload-gallery-container">
    <div
      v-for="(file, index) in uploadedFiles"
      :key="index"
      class="upload-gallery-item"
      :file="file"
    >
      <button @click.prevent="emit('removeFile', file)">
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 13 13"
        ><circle
          cx="6.5"
          cy="6.5"
          r="6"
          fill="#535353"
          stroke="#fff"
        /><path
          d="m4.2 4.2 4.6 4.6m-4.6 0 4.6-4.6"
          stroke="#fff"
          stroke-width="1.2"
          stroke-linecap="round"
        /></svg>
      </button>

      <img
        v-if="file.type.startsWith('image/')"
        :src="file.url"
        class="object-cover w-full h-full"
      />
      <template v-else-if="file.type.startsWith('video/')">
        <video controls="false">
          <source :src="file.url" :type="file.type" />
        </video>
      </template>
      <template v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-3/5 h-3/5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
          />
        </svg>
      </template>
    </div>

    <div class="upload-gallery-dropzone formkit-form formkit-form--gallery-upload">
      <div class="formkit-input-upload-area">
        <FormKit
          :id="`sugg_files1_${randomId}`"
          type="file"
          name="sugg_files"
          :accept="allowedMimeTypes.toString()"
          multiple="true"
          :sections-schema="{
            inner: {
              $el: 'div',
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class:
                      'formkit-input-upload-area-inner',

                  },
                  children: [
                    {
                      $el: 'input',
                      bind: '$attrs',
                      attrs: {
                        'type': 'file',
                        'disabled': '$disabled',
                        'name': '$node.name',
                        'onChange': '$handlers.files',
                        'onBlur': '$handlers.blur',
                        'id': '$id',
                        'aria-describedby': '$describedBy',
                      },
                    },
                    {
                      $el: 'div',
                      attrs: {
                        class:
                          'formkit-input-upload-mask',

                      },
                    },
                  ],
                },
              ],
            },
          }"
          @change="(e : any) => emit('uploadFile', e)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FileUploadResult } from '../types'

defineProps<{
  uploadedFiles: FileUploadResult[]
  allowedMimeTypes: string[]
  randomId: string
}>()

const emit = defineEmits<{
  (event: 'toggleGallery'): void
  (event: 'uploadFile', e: any): void
  (event: 'removeFile', file: any): void
}>()
</script>

<style>
  .upload-gallery-header {
    @apply flex items-center justify-between mb-3 mx-1;

    &__breadcrumb {
      @apply flex cursor-pointer;
      span {
        @apply pb-1 pl-1 text-xl font-bold font-greta;
      }

      svg {
        @apply w-4;
      }
    }

    &__close {
      @apply w-4 cursor-pointer;
    }
  }

  .upload-gallery-container {
    @apply flex flex-wrap gap-1 bg-white rounded min-h-96 content-start;
  }

    .upload-gallery-item {
        @apply relative flex items-center justify-center w-20 overflow-hidden sm:w-32 h-full bg-grau-500 rounded aspect-square;

        svg {
          @apply absolute w-4 top-1 right-1 md:w-5 aspect-square;
        }
      }

  .upload-gallery-dropzone {
    @apply relative flex items-start justify-center w-full h-full w-20 sm:w-32;
  }
</style>
